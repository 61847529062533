import { ref } from '@vue/composition-api'
import productRepository from '@/repository/productRepository'
import ability from '@/libs/acl/ability'

export default function productView() {
  const elementData = ref({})
  const errorData = ref(null)

  const getElement = id => new Promise(resolve => {
    if (!ability.can('read', 'Product')) {
      errorData.value = 'Недостаточно прав для чтения'
      return
    }
    productRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Товара с текущем идентификатором не найдено')
        }
        const { actions, ...element } = res
        elementData.value = {
          ...element,
          actions: actions.filter(x => x.active === 1 && x.activePeriod),
          createdUser: !res.createdUser ? '' : `${res.createdUser.firstName || ''} ${res.createdUser.lastName || ''}`,
          updatedUser: !res.updatedUser ? '' : `${res.updatedUser.firstName || ''} ${res.updatedUser.lastName || ''}`,
        }
        resolve()
      })
      .catch(error => {
        elementData.value = null
        errorData.value = error ? `${error}`.replace('Error: ', '') : ''
      })
  })

  return {
    elementData,
    errorData,

    getElement,
  }
}
