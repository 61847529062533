<template>
  <div>
    <b-alert
      v-if="errorData"
      variant="danger"
      :show="!elementData"
    >
      <h4 class="alert-heading">
        Ошибка при извлечении данных
      </h4>
      <div class="alert-body">
        {{ errorData }} Вернитесь
        <b-link
          class="alert-link"
          :to="{ name: 'action-list'}"
        >
          к списку
        </b-link>
        товаров.
      </div>
    </b-alert>

    <template v-if="elementData">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <img
                :src="elementData.file && elementData.file.path ? elementData.file.path : '/no-image.png'"
                class="product-img img-fluid"
              >
            </div>
          </b-col>
          <b-col
            cols="12"
            md="7"
          >
            <h3>{{ elementData.name }}</h3>

            <b-card-text class="item-company mb-0">
              <div class="item-property  text-muted font-small-3">
                <div class="item-property-label">
                  Производитель
                </div>
                <div class="item-property-text">
                  {{ elementData.brand }}
                </div>
                <div class="item-property-label">
                  Артикул
                </div>
                <div class="item-property-text">
                  {{ elementData.articul }}
                </div>
              </div>
              <div
                v-if="elementData.actionPrice && elementData.actionPrice.quantity"
                class="price-quantity"
              >
                <div class="d-flex align-items-left justify-content-start mt-1">
                  <b-img :src="boxQuantity" />
                  <div class="text-primary">
                    +{{ elementData.actionPrice.quantity.stepProfit }}
                  </div>
                  <div> - за {{ elementData.actionPrice.quantity.step }} шт.</div>
                </div>
              </div>
              <div class="d-flex align-items-left align-bottom justify-content-start mt-2 mb-1">
                <b-img :src="homeBonus" />
                <b-img
                  v-if="elementData.actionPrice && elementData.actionPrice.bonus && elementData.actionPrice.bonus"
                  :src="arrowPrice"
                />
                <div class="price-main font-weight-bold ml-1">
                  {{ elementData.actionPrice && elementData.actionPrice.bonus && elementData.actionPrice.bonus ? elementData.actionPrice.bonus.profit : elementData.actionPrice && elementData.actionPrice.standard && elementData.actionPrice.standard.profit ? elementData.actionPrice.standard.profit : '5' }}B
                </div>
                <div
                  v-if="elementData.actionPrice && elementData.actionPrice.bonus && elementData.actionPrice.bonus"
                  class="price-old text-muted small"
                >
                  <s>{{ elementData.actionPrice && elementData.actionPrice.standard && elementData.actionPrice.standard.profit ? elementData.actionPrice.standard.profit : '5' }}B</s>
                </div>
              </div>
              <div
                v-if="(elementData.actions || []).length > 0"
                class="h5 mt-2 mb-1"
              >
                Участвует в Акциях:
              </div>
              <template v-if="(elementData.actions || []).length > 0">
                <b-card-actions
                  v-for="action in elementData.actions"
                  :key="action.id"
                  class="card-actions-items border mb-1"
                  action-collapse
                  collapsed
                >
                  <template v-slot:title>
                    <b-card-title>
                      <div
                        class="action-icon mr-2"
                        :style="{ 'background-color': `#${ action.action && action.action.color && action.action.color.color ? action.action.color.color : '' }`}"
                      >
                        <feather-icon
                          icon="GithubIcon"
                          size="18"
                          :style="{ 'color': '#fff'}"
                        />
                      </div>
                      <span class="title d-inline"><span class="h4">{{ action.action.title }}</span></span>
                    </b-card-title>
                  </template>
                  <b-card-text>
                    <hr>
                    <b-row>
                      <b-col cols="12">
                        <div class="d-flex justify-content-start align-items-center">
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="font-weight-bold mr-1">c {{ formatDate(action.action.activeFrom) }} по  {{ formatDate(action.action.activeTo) }}</span>
                        </div>
                      </b-col>
                      <b-col
                        v-if="action.action.type === 'quantity'"
                        cols="12"
                      >
                        <div class="d-flex justify-content-start align-items-center">
                          <feather-icon
                            icon="ShoppingCartIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="font-weight-bold">за продажу {{ action.action.step }} штук</span>
                          <span
                            class="font-weight-bold text-danger ml-1"
                            style="font-size: 20px;"
                          > {{ action.action.profit }}</span>
                          <feather-icon
                            icon="BoldIcon"
                            class="text-danger"
                            size="18"
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                      <b-col cols="12">
                        {{ action.action.description }}
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-actions>
              </template>
            </b-card-text>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </div>
</template>

<script>
/* eslint-disable global-require */
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BCardText, BCardTitle, BImg,
} from 'bootstrap-vue'
import BCardActions from '@/components/b-card-actions/BCardActions.vue'
import { formatDate } from '@core/utils/filter'
import productView from './productView'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardText,
    BCardTitle,
    BImg,

    BCardActions,
  },
  data() {
    return {
      arrowPrice: require('@/assets/images/price-group/arrow_price.svg'),
      boxQuantity: require('@/assets/images/price-group/box_quantity.svg'),
      homeBonus: require('@/assets/images/price-group/home_bonus.svg'),
    }
  },
  setup() {
    const productId = ref(parseInt(router.currentRoute.params.id, 10))
    const {
      elementData,
      errorData,

      getElement,
    } = productView()

    getElement(productId.value).then()

    return {
      elementData, errorData, formatDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.item-property {
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 3fr;
  font-weight: 500;
  text-align: left;
  .item-property-label {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .item-property-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.action-icon {
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
}
</style>

<style lang="scss">
.card-actions-items .card-header {
  padding: 1em 2.5rem 1em 1.5rem !important;
  .card-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .heading-elements {
    position: absolute !important;
    top: 40% !important;
    right: 1.5rem !important;
  }
}
.price-main{
  font-size: 24px;
  line-height: 36px;
  padding-right: 10px;
}
.price-old{
  margin-top: 10px;
}
.price-quantity {
  font-size: 16px;
  font-weight: 500;
}
</style>
